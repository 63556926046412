import { DateTime } from "luxon";

export const ZeroDate = DateTime.fromISO("0001-01-01T00:00:00Z", {
  zone: "utc",
});
export const isZeroDate = (date: string) => {
  return DateTime.fromISO(date, { zone: "utc" }).equals(ZeroDate);
};
export const formatDateShort = (date: string, isLocal?: boolean) => {
  if (isLocal) {
    return DateTime.fromISO(date, { setZone: true }).toLocaleString(
      DateTime.DATETIME_SHORT
    );
  }
  return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_SHORT);
};

export const getDateTimeNoTimeZone = (date: string) => {
  return DateTime.fromISO(date, { setZone: true });
};

export const formatShortTime = (time: DateTime) => {
  return time.toLocaleString(DateTime.TIME_24_SIMPLE);
};

export const formatMediumDate = (date: string) => {
  return DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED);
};

export const formatMonthDay = (date: string) => {
  return DateTime.fromISO(date).toUTC().toFormat("M/dd");
};

export const formatMonthDayWithoutTrailingZero = (date: string) => {
  return DateTime.fromISO(date).toUTC().toFormat("M/d");
};

export const formatMonthDayYear = (date: string) => {
  return DateTime.fromISO(date).toUTC().toFormat("M/dd/yy");
};

export const formatMonthDayYearWithWeekday = (date: string) => {
  return DateTime.fromISO(date)
    .toUTC()
    .toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);
};

export const getJsDateFromBackendJson = (jsonstring: string): Date => {
  const [year, month, day] = jsonstring.slice(0, 10).split("-");
  const monthNumber = Number.parseInt(month);
  return new Date(Number.parseInt(year), monthNumber - 1, Number.parseInt(day));
};

export const daysBetweenDates = (date1: Date, date2: Date) => {
  const diffTime = Math.abs(date2.getTime() - date1.getTime());
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

export const analyticsFilterFormat = (date: Date) => {
  return DateTime.fromJSDate(date).toISODate();
};

export const convertDateRangeToSingularDate = (name: string) => {
  // If the dates are the same, just return the date
  if (!name.includes(" - ")) {
    return name;
  }
  const splitName = name.split(" - ");
  if (splitName[0] === splitName[1]) {
    return splitName[0];
  }
  return name;
};

export const deriveComparisonDatesLabel = (start: Date, end: Date) => {
  if (start.toISOString() === end.toISOString()) {
    return formatMonthDayYear(start.toISOString());
  }
  return `${formatMonthDayYear(start.toISOString())} - ${formatMonthDayYear(
    end.toISOString()
  )}`;
};
