import React, { useMemo } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  Day,
  formatToTwelveHour,
  WeekdayWithHours,
  SetHour,
} from "common/dates";

interface Props {
  weekdaysWithHours: WeekdayWithHours[];
}

interface CombinedHoursItem {
  title: string; // e.g. "Mon - Fri"
  setHours: SetHour[];
  isAlwaysClosed: boolean;
  isAlwaysOpen: boolean;
  daysWithTheseHours: Day[];
}

const combineCommonSetHours = (
  weekdaysWithHours: WeekdayWithHours[]
): CombinedHoursItem[] => {
  const combinedHours: CombinedHoursItem[] = [];
  const weekdaysWithHoursCopy = [...weekdaysWithHours];
  let currentCombinedHours: CombinedHoursItem = {
    title: "",
    setHours: [],
    isAlwaysClosed: false,
    isAlwaysOpen: false,
    daysWithTheseHours: [],
  };
  for (let i = 0; i < weekdaysWithHoursCopy.length; i++) {
    const weekdayWithHours = weekdaysWithHoursCopy[i];
    if (currentCombinedHours.setHours.length === 0) {
      currentCombinedHours.title = weekdayWithHours.day.name.slice(0, 3);
      currentCombinedHours.setHours = weekdayWithHours.setHours;
      currentCombinedHours.isAlwaysClosed = weekdayWithHours.isAlwaysClosed;
      currentCombinedHours.isAlwaysOpen = weekdayWithHours.isAlwaysOpen;
      currentCombinedHours.daysWithTheseHours.push(weekdayWithHours.day);
    } else {
      const isSameHours = weekdayWithHours.setHours.every((setHour, index) => {
        if (!currentCombinedHours.setHours[index]) {
          return false;
        }

        return (
          setHour.open === currentCombinedHours.setHours[index].open &&
          setHour.close === currentCombinedHours.setHours[index].close
        );
      });
      const isSameAlwaysClosed =
        weekdayWithHours.isAlwaysClosed === currentCombinedHours.isAlwaysClosed;
      const isSameAlwaysOpen =
        weekdayWithHours.isAlwaysOpen === currentCombinedHours.isAlwaysOpen;
      if (isSameHours && isSameAlwaysClosed && isSameAlwaysOpen) {
        currentCombinedHours.title = `${
          currentCombinedHours.title
        } - ${weekdayWithHours.day.name.slice(0, 3)}`;
        currentCombinedHours.daysWithTheseHours.push(weekdayWithHours.day);
      } else {
        combinedHours.push(currentCombinedHours);
        currentCombinedHours = {
          title: weekdayWithHours.day.name.slice(0, 3),
          setHours: weekdayWithHours.setHours,
          isAlwaysClosed: weekdayWithHours.isAlwaysClosed,
          isAlwaysOpen: weekdayWithHours.isAlwaysOpen,
          daysWithTheseHours: [weekdayWithHours.day],
        };
      }
    }
  }
  combinedHours.push(currentCombinedHours);
  return combinedHours;
};

const daysIncludeToday = (days: Day[]) => {
  const today = new Date().getDay();
  return days.some((day) => day.id === today - 1);
};

const HoursBlock: React.FC<Props> = ({ weekdaysWithHours }) => {
  const combinedHours = useMemo(
    () => combineCommonSetHours(weekdaysWithHours),
    [weekdaysWithHours]
  );
  return (
    <Box px={2} py={2}>
      {combinedHours.length > 0 &&
        combinedHours.map((combinedHour, i) => {
          const isToday = daysIncludeToday(combinedHour.daysWithTheseHours);
          const {
            setHours,
            isAlwaysClosed,
            isAlwaysOpen,
            daysWithTheseHours,
            title,
          } = combinedHour;
          const formattedTitle =
            daysWithTheseHours.length > 1
              ? `${title.split(" - ")[0]} - ${
                  title.split(" - ")[title.split(" - ").length - 1]
                }`
              : title;
          const todayStyles = isToday
            ? {
                fontWeight: 500,
                color: isAlwaysClosed ? "error.main" : "success.main",
              }
            : {};
          return (
            <Box
              key={`${title}-${i}`}
              display="flex"
              alignItems="start"
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center" position="relative">
                {isToday && (
                  <Box
                    bgcolor={isAlwaysClosed ? "error.main" : "success.main"}
                    height={14}
                    width={4}
                    borderRadius={8}
                    left={-8}
                    position="absolute"
                  />
                )}
                <Typography variant="body2" {...todayStyles}>
                  {formattedTitle}
                </Typography>
              </Box>
              {isAlwaysClosed && (
                <Typography variant="body2" {...todayStyles}>
                  Closed
                </Typography>
              )}
              {isAlwaysOpen && (
                <Typography variant="body2" {...todayStyles}>
                  Open 24/7
                </Typography>
              )}
              {setHours.length > 0 && !isAlwaysClosed && !isAlwaysOpen && (
                <Box>
                  {setHours.map((setHour, index) => (
                    <Typography variant="body2" {...todayStyles} key={index}>
                      {formatToTwelveHour(setHour.open)} -{" "}
                      {formatToTwelveHour(setHour.close)}
                    </Typography>
                  ))}
                </Box>
              )}
            </Box>
          );
        })}
    </Box>
  );
};

export default HoursBlock;
