import React from "react";
import { DateTime } from "luxon";
import {
  GridRowsProp,
  GridColDef,
  GridColumnGroupingModel,
  GridColumnVisibilityModel,
  gridStringOrNumberComparator,
} from "@mui/x-data-grid";
import {
  deriveComparisonDatesLabel,
  formatMediumDate,
  formatMonthDayWithoutTrailingZero,
  formatMonthDayYear,
  formatMonthDayYearWithWeekday,
} from "../dates";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { AnyVal, SpaceNodeByIdMap } from "../interfaces";
import { ComparisonType, DateRangeListItem } from "./analytics-space-view";
import { deriveChartYAxisMax, deriveColor } from "./charts-common";
import {
  ChartSeriesMetaDataItem,
  ChartSeriesType,
  deriveDefaultYAxisOptions,
  initialDateOptions,
} from "./datechart";
import { formatNumberWithComma } from "../util/format";
import { formatToTwelveHour } from "../numbers";
import {
  dataGridHeaderStyles,
  purpleLight,
  purple,
  greyThree,
  greyTwo,
} from "../theme";

export const deriveDateChartDatestooltip = (capacity: number) => {
  return {
    tooltip: {
      formatter: function () {
        const parsedMetaData = JSON.parse((this as AnyVal).key);
        const date = new Date(parsedMetaData.date);
        const spaceName = parsedMetaData.spaceName;
        const formattedDate = formatMonthDayYearWithWeekday(date.toISOString());
        const percent = Math.round((100 * (this as AnyVal).y) / capacity);
        return `<b>${formattedDate}</b><br />${spaceName} Occupancy: ${
          (this as AnyVal).y
        } (${percent}%)`;
      },
    },
  };
};

export const deriveEarlyPreviewDateChartTooltip: AnyVal = () => {
  return {
    tooltip: {
      formatter: function () {
        const parsedMetaData = JSON.parse((this as AnyVal).key);
        const date = new Date(parsedMetaData.date);
        const formattedDate = formatMonthDayYearWithWeekday(date.toISOString());
        return `<b>${formattedDate}`;
      },
    },
  };
};

export const deriveDateChartSpacestooltip: AnyVal = (showPercent: boolean) => {
  return {
    tooltip: {
      formatter: function () {
        const parsedMetaData = JSON.parse((this as AnyVal).key);
        const date = new Date(parsedMetaData.date);
        const formattedDate = formatMonthDayYearWithWeekday(date.toISOString());

        const occupancy = (this as AnyVal).y;
        const text = showPercent
          ? `${occupancy} (${parsedMetaData.percentOccupancy}%)`
          : `${occupancy}`;
        return `<b>${formattedDate}</b><br />${
          (this as AnyVal).series.name
        } Occupancy: ${text}`;
      },
    },
  };
};

export const deriveDateChartSpacesPercentagetooltip: AnyVal = () => {
  return {
    tooltip: {
      formatter: function () {
        const parsedMetaData = JSON.parse((this as AnyVal).key);
        const date = new Date(parsedMetaData.date);
        const formattedDate = formatMonthDayYearWithWeekday(date.toISOString());
        return `<b>${formattedDate}</b><br />${
          (this as AnyVal).series.name
        } Percent Occupancy: ${(this as AnyVal).y}%`;
      },
    },
  };
};

export const deriveColumnPaddingOptions = (
  hasComparison: boolean,
  showEarlyPreview: boolean
) => {
  if (hasComparison) {
    return {
      plotOptions: {
        column: {
          pointPadding: 0,
          opacity: 0.9,
          borderColor: showEarlyPreview ? purple : undefined,
        },
      },
    };
  }
  // Decreases the padding to make the columns wider
  return {
    plotOptions: {
      column: {
        pointPadding: -0.1,
        opacity: 0.9,
        borderColor: showEarlyPreview ? purple : undefined,
      },
    },
  };
};

export const deriveComparisonColumnSeries: AnyVal = (
  data: AnyVal,
  seriesName: string,
  color: string,
  shouldDisplayAverage: boolean
) => {
  return {
    type: "column",
    name: seriesName,
    data,
    borderRadius: 4,
    color,
    showInLegend: false,
    visible: shouldDisplayAverage,
  };
};

export const deriveComparisonScatterSeries: AnyVal = (
  data: AnyVal,
  seriesName: string,
  color: string,
  shouldDisplayPeak: boolean
) => {
  return {
    type: "scatter",
    name: seriesName,
    data,
    color,
    marker: {
      symbol: "diamond",
      lineColor: "black",
      lineWidth: 1,
      radius: 3,
    },
    showInLegend: false,
    zIndex: 1, // so that the peak dots are on top of the bars
    visible: shouldDisplayPeak,
  };
};

const deriveScatterXValue = (
  i: number,
  barCount: number,
  indexOfBar: number
) => {
  // These offsets are used to position the scatter dots on top of the bars
  // when comparing multiple spaces or time ranges
  const offSet = 0.15;
  const offSet2 = 0.2;
  const offSet3 = 0.08;
  const offSet4 = 0.22;
  const offSet5 = 0.125;
  const offSet6 = 0.24;
  const offSet7 = 0.05;
  const offSet8 = 0.15;
  const offSet9 = 0.25;
  if (barCount === 1) {
    return i;
  }
  if (barCount === 2) {
    if (indexOfBar === 0) return i - offSet;
    if (indexOfBar === 1) return i + offSet;
  }
  if (barCount === 3) {
    if (indexOfBar === 0) return i - offSet2;
    if (indexOfBar === 1) return i;
    if (indexOfBar === 2) return i + offSet2;
  }
  if (barCount === 4) {
    if (indexOfBar === 0) return i - offSet4;
    if (indexOfBar === 1) return i - offSet3;
    if (indexOfBar === 2) return i + offSet3;
    if (indexOfBar === 3) return i + offSet4;
  }
  if (barCount === 5) {
    if (indexOfBar === 0) return i - offSet6;
    if (indexOfBar === 1) return i - offSet5;
    if (indexOfBar === 2) return i;
    if (indexOfBar === 3) return i + offSet5;
    if (indexOfBar === 4) return i + offSet6;
  }
  if (barCount === 6) {
    if (indexOfBar === 0) return i - offSet9;
    if (indexOfBar === 1) return i - offSet8;
    if (indexOfBar === 2) return i - offSet7;
    if (indexOfBar === 3) return i + offSet7;
    if (indexOfBar === 4) return i + offSet8;
    if (indexOfBar === 5) return i + offSet9;
  }

  return i;
};

const formatDateChartSpaceComparisonSeriesItem = (
  data: AnyVal,
  capacity: number,
  dataMax: number,
  isScatter?: boolean,
  barCount?: number,
  indexOfBar?: number
) => {
  const newData = data.map((datum: AnyVal, i: number) => {
    if (datum[1] > dataMax) {
      dataMax = datum[1];
    }
    const x =
      isScatter && barCount && indexOfBar !== undefined
        ? deriveScatterXValue(i, barCount, indexOfBar)
        : i;
    return {
      x,
      y: datum[1],
      name: JSON.stringify({
        percentOccupancy: Math.round((100 * datum[1]) / capacity),
        date: datum[0],
      }),
    };
  });
  return { newData, dataMax };
};

export const deriveDateChartSpacesMetaData = (
  parsedData: AnyVal[],
  nodes: SpaceNodeByIdMap,
  comparisonSpaceIds: string[],
  oldChartMetaData: ChartSeriesMetaDataItem[],
  shouldDisplayAverage: boolean,
  shouldDisplayPeak: boolean,
  showEarlyPreview: boolean
) => {
  const dayData = parsedData[0];
  if (!dayData) {
    return { daySeriesMetaData: [], xAxis: [], dataMax: 0 };
  }
  const daySeriesMetaData: ChartSeriesMetaDataItem[] = [];
  let dataMax = 0;
  comparisonSpaceIds.forEach((id: string, indexOfBar: number) => {
    const chartData = dayData[id];
    const node = nodes[id];
    const capacity = node.capacity;
    const oldChartItem = oldChartMetaData.find((item) => item.id === node.id);
    const color = showEarlyPreview
      ? greyTwo
      : deriveColor(oldChartItem, daySeriesMetaData);

    if (!chartData) {
      return { daySeriesMetaData: [], xAxis: [], dataMax: 0 };
    }

    const { newData: formattedAvg } = formatDateChartSpaceComparisonSeriesItem(
      chartData.Avg,
      capacity,
      dataMax
    );
    const { newData: formattedAvgPercent } =
      formatDateChartPercentOccupancySeriesItem(
        chartData.Avg,
        capacity,
        dataMax
      );
    const { newData: formattedPeak, dataMax: max3 } =
      formatDateChartSpaceComparisonSeriesItem(
        chartData.Peak,
        capacity,
        dataMax,
        true,
        comparisonSpaceIds.length,
        indexOfBar
      );
    const { newData: formattedPeakPercent } =
      formatDateChartPercentOccupancySeriesItem(
        chartData.Peak,
        capacity,
        dataMax,
        true,
        comparisonSpaceIds.length,
        indexOfBar
      );
    // gets peak max
    dataMax = max3;
    daySeriesMetaData.push({
      name: node.name,
      seriesName: node.name + " Average",
      color,
      id: node.id,
      type: ChartSeriesType.Average,
      series: deriveComparisonColumnSeries(
        formattedAvg,
        node.name + " Average",
        color,
        shouldDisplayAverage
      ),
      percentageSeries: deriveComparisonColumnSeries(
        formattedAvgPercent,
        node.name + " Average",
        color,
        shouldDisplayAverage
      ),
    });
    daySeriesMetaData.push({
      name: node.name,
      seriesName: node.name + " Peak",
      color,
      id: node.id,
      type: ChartSeriesType.Peak,
      series: deriveComparisonScatterSeries(
        formattedPeak,
        node.name + " Peak",
        color,
        shouldDisplayPeak
      ),
      percentageSeries: deriveComparisonScatterSeries(
        formattedPeakPercent,
        node.name + " Peak",
        color,
        shouldDisplayPeak
      ),
    });
  });
  const xAxis: AnyVal[] = [];
  return { daySeriesMetaData, xAxis, dataMax };
};

export const deriveDateChartDatesMetaData = (
  parsedData: AnyVal,
  nodes: SpaceNodeByIdMap,
  comparisonDateRanges: AnyVal[],
  dateRange: AnyVal,
  oldChartMetaData: ChartSeriesMetaDataItem[],
  shouldDisplayAverage: boolean,
  shouldDisplayPeak: boolean
) => {
  const daySeriesMetaData: ChartSeriesMetaDataItem[] = [];

  const datesToCompare: DateRangeListItem[] = [
    { ...dateRange, indexOfDateRange: 0 },
    ...comparisonDateRanges,
  ];

  let dataMax = 0;

  const xAxis: string[] = [];
  datesToCompare.forEach(
    ({ startDate, indexOfDateRange, endDate }, indexOfBar) => {
      const comparisonLabel = deriveComparisonDatesLabel(startDate, endDate);
      const spaceData = parsedData[indexOfDateRange];
      const formatDateData = (
        data: AnyVal,
        spaceName: string,
        isScatter?: boolean,
        barCount?: number,
        indexOfBar?: number
      ) => {
        return data.map((datum: AnyVal, i: number) => {
          if (datum[1] > dataMax) {
            dataMax = datum[1];
          }
          const x =
            isScatter && barCount && indexOfBar !== undefined
              ? deriveScatterXValue(i, barCount, indexOfBar)
              : i;
          return {
            x,
            y: datum[1],
            name: JSON.stringify({
              spaceName,
              date: datum[0],
            }),
          };
        });
      };
      const oldChartItem = oldChartMetaData.find(
        (item) => item.name === comparisonLabel
      );
      const color = deriveColor(oldChartItem, daySeriesMetaData);

      Object.keys(spaceData).forEach((id) => {
        const chartData = spaceData[id];
        const node = nodes[id];

        chartData.Avg.forEach((datum: AnyVal) => {
          xAxis.push(datum[0]);
        });

        daySeriesMetaData.push({
          name: comparisonLabel,
          seriesName: comparisonLabel + " Average",
          color,
          id: node.id,
          type: ChartSeriesType.Average,
          series: deriveComparisonColumnSeries(
            formatDateData(chartData.Avg, node.name + " Average"),
            comparisonLabel + " Average",
            color,
            shouldDisplayAverage
          ),
          percentageSeries: deriveComparisonColumnSeries(
            formatDateData(chartData.AvgPercent, node.name + " Average"),
            comparisonLabel + " Average",
            color,
            shouldDisplayAverage
          ),
        });
        daySeriesMetaData.push({
          name: comparisonLabel,
          seriesName: comparisonLabel + " Peak",
          color,
          id: node.id,
          type: ChartSeriesType.Peak,
          series: deriveComparisonScatterSeries(
            formatDateData(
              chartData.Peak,
              node.name + " Peak",
              true,
              datesToCompare.length,
              indexOfBar
            ),
            comparisonLabel + " Peak",
            color,
            shouldDisplayPeak
          ),
          percentageSeries: deriveComparisonScatterSeries(
            formatDateData(
              chartData.PeakPercent,
              node.name + " Peak",
              true,
              datesToCompare.length,
              indexOfBar
            ),
            comparisonLabel + " Peak",
            color,
            shouldDisplayPeak
          ),
        });
      });
    }
  );
  return { daySeriesMetaData, xAxis, dataMax };
};

const formatDateChartPercentOccupancySeriesItem = (
  data: AnyVal,
  capacity: number,
  dataMax: number,
  isScatter?: boolean,
  barCount?: number,
  indexOfBar?: number
) => {
  const newData = data.map((datum: AnyVal, i: number) => {
    if (datum[1] > dataMax) {
      dataMax = datum[1];
    }
    const x =
      isScatter && barCount && indexOfBar !== undefined
        ? deriveScatterXValue(i, barCount, indexOfBar)
        : i;
    const percentOccupancy = Math.round((100 * datum[1]) / capacity);
    return {
      x,
      y: percentOccupancy,
      name: JSON.stringify({
        percentOccupancy,
        date: datum[0],
      }),
    };
  });
  return { newData, dataMax };
};

export const deriveDateChartXAxisOptions = (
  isDateComparison: boolean,
  xAxis: AnyVal[],
  isDateComparisonSingleDay: boolean
) => {
  if (isDateComparison) {
    // TODO: investigate better x axis
    if (isDateComparisonSingleDay) {
      return {
        xAxis: {
          labels: {
            formatter: function (value: AnyVal) {
              const date = new Date(xAxis[value.pos]);
              return DateTime.fromISO(date.toISOString())
                .toUTC()
                .get("weekdayShort");
            },
          },
        },
      };
    }
    return {
      xAxis: {
        labels: {
          formatter: function (value: AnyVal) {
            const date = new Date(xAxis[value.pos]);
            return DateTime.fromISO(date.toISOString())
              .toUTC()
              .get("weekdayShort");
          },
        },
      },
    };
  }
  return {
    xAxis: {
      labels: {
        formatter: function () {
          // TODO: remove below and check for a better solution
          // const dateString = JSON.parse((this as AnyVal).value).date;
          const value = (this as AnyVal)?.chart?.options?.series[0].data[
            (this as AnyVal).pos
          ];
          if (!value) {
            // There is an odd case with larger date ranges that conflicts with how we are generating a custom number of ticks
            // The final tick in these cases are beyond the scope of the dates in the data, so we need to handle this case
            return "";
          }

          const dateString = JSON.parse(value?.name).date;
          const date = new Date(dateString);
          return formatMonthDayWithoutTrailingZero(date.toISOString());
        },
      },
    },
  };
};

export const deriveDateChartOptions = (
  parsedData: AnyVal,
  isDateComparison: boolean,
  shouldDisplayAverage: boolean,
  shouldDisplayPeak: boolean,
  isYAxisZoomed: boolean,
  options: Highcharts.Options,
  isYAxisPercentage: boolean,
  nodes: SpaceNodeByIdMap,
  comparisonSpaceIds: string[],
  comparisonDateRanges: AnyVal[],
  chartMetaData: ChartSeriesMetaDataItem[],
  dateRange: AnyVal,
  capacity: number,
  showEarlyPreview: boolean
) => {
  const newIsYAxisPercentage = isDateComparison ? false : isYAxisPercentage;
  const { daySeriesMetaData, xAxis, dataMax } = isDateComparison
    ? deriveDateChartDatesMetaData(
        parsedData,
        nodes,
        comparisonDateRanges,
        dateRange,
        chartMetaData,
        shouldDisplayAverage,
        shouldDisplayPeak
      )
    : deriveDateChartSpacesMetaData(
        parsedData,
        nodes,
        comparisonSpaceIds,
        chartMetaData,
        shouldDisplayAverage,
        shouldDisplayPeak,
        showEarlyPreview
      );
  const series = daySeriesMetaData.map((item) =>
    newIsYAxisPercentage ? item.percentageSeries : item.series
  );

  const showSecondYAxis = Boolean(comparisonSpaceIds.length === 1);

  const yAxisMax = deriveChartYAxisMax(
    newIsYAxisPercentage,
    isYAxisZoomed,
    capacity,
    dataMax
  );

  const deriveToolTip = () => {
    if (showEarlyPreview) {
      return deriveEarlyPreviewDateChartTooltip();
    }
    if (isDateComparison) {
      return deriveDateChartDatestooltip(capacity);
    }
    if (newIsYAxisPercentage) {
      return deriveDateChartSpacesPercentagetooltip();
    }
    const showPercent = Boolean(comparisonSpaceIds.length === 1);
    return deriveDateChartSpacestooltip(showPercent);
  };

  const toolTip = deriveToolTip();

  const hasComparison = Boolean(
    comparisonDateRanges.length > 0 || comparisonSpaceIds.length > 1
  );

  const isDateComparisonSingleDay =
    isDateComparison &&
    dateRange.startDate.toISOString() === dateRange.endDate.toISOString();

  const xAxisOptions = deriveDateChartXAxisOptions(
    isDateComparison,
    xAxis,
    isDateComparisonSingleDay
  );

  const newOptions = {
    ...initialDateOptions,
    ...options,
    ...toolTip,
    ...deriveDefaultYAxisOptions(
      yAxisMax,
      capacity,
      showSecondYAxis,
      isYAxisZoomed,
      newIsYAxisPercentage,
      showEarlyPreview
    ),
    ...deriveColumnPaddingOptions(hasComparison, showEarlyPreview),
    ...xAxisOptions,
    series,
  };
  return { newOptions, daySeriesMetaData, dataMax };
};

// =================================================================================================
// Table stuff
// =================================================================================================

const deriveDefaultColumnProps = (
  isMobileSized: boolean
): Partial<GridColDef> => {
  return {
    flex: 1,
    align: "left",
    headerAlign: "left",
    sortable: !isMobileSized, // Removes sort on mobile
    sortComparator: gridStringOrNumberComparator,
    renderCell: undefined,
  };
};

const dateColFormatter = (
  params: AnyVal,
  isMobileSized: boolean,
  comparisonType?: ComparisonType
) => {
  if (comparisonType) {
    return formatToTwelveHour(params.value as string);
  }
  return isMobileSized
    ? formatMonthDayYear(params.value as string)
    : DateTime.fromISO(params.value as string).toLocaleString(
        DateTime.DATE_MED
      );
};

const deriveAvgOccupancyAltText = (comparisonType?: ComparisonType) =>
  comparisonType === ComparisonType.HourlyOccupancy
    ? "The average occupancy of this space for the hour indicated over the date range set above"
    : "The average occupancy of this space for the date indicated and the time range set above";
const deriveAvgPercentOccupiedAltText = (comparisonType?: ComparisonType) =>
  comparisonType === ComparisonType.HourlyOccupancy
    ? "The average percent occupied this space is for the hour indicated over the date range set above, and based on the set capacity of this space"
    : "The average percent occupied this space is for the date indicated and the time range set above, and based on the set capacity of this space";
const derivePeakOccupancyAltText = (comparisonType?: ComparisonType) =>
  comparisonType === ComparisonType.HourlyOccupancy
    ? "The peak level of occupancy of this space for the hour indicated and the date range set above"
    : "The peak level of occupancy of this space for the date indicated and the time range set above";
const derivePeakPercentOccupiedAltText = (comparisonType?: ComparisonType) =>
  comparisonType === ComparisonType.HourlyOccupancy
    ? "The peak percent occupied this space is for the hour indicated over the date range set above, and based on the set capacity of this space"
    : "The peak percent occupied this space is for the date indicated and the time range set above, and based on the set capacity of this space";

const HeaderWithTooltip = ({
  tooltip,
  headerName,
}: {
  tooltip: string;
  headerName: string;
}) => {
  return (
    <Tooltip title={tooltip}>
      <Typography sx={{ ...dataGridHeaderStyles }}>{headerName}</Typography>
    </Tooltip>
  );
};

const deriveDefaultDailyColumns = (
  isMobileSized: boolean,
  comparisonType?: ComparisonType
): GridColDef[] => {
  const valueFormatter = ({ value }: { value: AnyVal }) => {
    if (value === null) {
      return "-";
    }
    return formatNumberWithComma(value);
  };
  const avgOccupancyAltText = deriveAvgOccupancyAltText(comparisonType);
  const avgPercentOccupiedAltText =
    deriveAvgPercentOccupiedAltText(comparisonType);
  const peakOccupancyAltText = derivePeakOccupancyAltText(comparisonType);
  const peakPercentOccupiedAltText =
    derivePeakPercentOccupiedAltText(comparisonType);
  return [
    {
      field: "col1",
      headerName:
        comparisonType === ComparisonType.HourlyOccupancy ? "Hour" : "Date",
      type: "date",
      ...deriveDefaultColumnProps(isMobileSized),
      align: "left",
      headerAlign: "left",
      valueFormatter: (params) =>
        dateColFormatter(params, isMobileSized, comparisonType),
      minWidth: 90,
    },
    {
      field: "average1",
      headerName: isMobileSized ? "Avg" : "Avg Occupancy",
      type: "number",
      ...deriveDefaultColumnProps(isMobileSized),
      valueFormatter: valueFormatter,
      renderHeader(params) {
        return (
          <HeaderWithTooltip
            tooltip={avgOccupancyAltText}
            headerName={params.colDef.headerName || ""}
          />
        );
      },
    },
    {
      field: "average2",
      headerName: isMobileSized ? "Avg %" : "Avg % Occupied",
      type: "number",
      ...deriveDefaultColumnProps(isMobileSized),
      valueFormatter: valueFormatter,
      renderHeader(params) {
        return (
          <Tooltip title={avgPercentOccupiedAltText}>
            <Typography sx={{ ...dataGridHeaderStyles }}>
              {params.colDef.headerName}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "peak1",
      headerName: isMobileSized ? "Peak" : "Peak Occupancy",
      type: "number",
      ...deriveDefaultColumnProps(isMobileSized),
      valueFormatter: valueFormatter,
      renderHeader(params) {
        return (
          <Tooltip title={peakOccupancyAltText}>
            <Typography sx={{ ...dataGridHeaderStyles }}>
              {params.colDef.headerName}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "peak2",
      headerName: isMobileSized ? "Peak %" : "Peak % Occupied",
      type: "number",
      ...deriveDefaultColumnProps(isMobileSized),
      valueFormatter: valueFormatter,
      renderHeader(params) {
        return (
          <Tooltip title={peakPercentOccupiedAltText}>
            <Typography sx={{ ...dataGridHeaderStyles }}>
              {params.colDef.headerName}
            </Typography>
          </Tooltip>
        );
      },
    },
  ];
};

// TODO: common?
const derivePercentCapacity = (capacity: number, occupancy: number) => {
  if (occupancy === null) {
    return null;
  }
  return Math.round((100 * occupancy) / capacity);
};

export const deriveTableVisibilityModel = (
  comparisonSpaceIds: string[],
  comparisonDateRanges: AnyVal[],
  tableShouldShowPeak: boolean,
  isMobileSized: boolean
): GridColumnVisibilityModel => {
  if (
    comparisonSpaceIds.length > 3 ||
    comparisonDateRanges.length > 2 ||
    (isMobileSized && comparisonSpaceIds.length > 1)
  ) {
    if (tableShouldShowPeak) {
      return {
        average1: false,
        average2: false,
        average3: false,
        average4: false,
        average5: false,
      };
    } else {
      return {
        peak1: false,
        peak2: false,
        peak3: false,
        peak4: false,
        peak5: false,
      };
    }
  }
  return {};
};

export const deriveTableSpacesComparisonData = (
  dateRange: AnyVal,
  availableDays: string[],
  comparisonDateRanges: AnyVal[],
  isDateComparison: boolean,
  tableShouldShowPeak: boolean,
  comparisonSpaceIds: string[],
  parsedData: AnyVal,
  nodes: SpaceNodeByIdMap,
  isMobileSized: boolean,
  comparisonType?: ComparisonType
): [
  rows: GridRowsProp,
  columns: GridColDef[],
  columnGroupingModel: GridColumnGroupingModel,
  columnVisibilityModel: GridColumnVisibilityModel
] => {
  if (isDateComparison) {
    // TODO: clean this func up
    return deriveTableDatesComparisonData(
      comparisonSpaceIds[0],
      dateRange,
      availableDays,
      tableShouldShowPeak,
      comparisonDateRanges,
      parsedData,
      nodes,
      isMobileSized,
      comparisonType
    );
  }
  const dailyData = parsedData[0];
  if (!dailyData) {
    return [[], [], [], {}];
  }

  const deriveSpacesColumns = (): GridColDef[] => {
    if (comparisonSpaceIds.length === 1) {
      return deriveDefaultDailyColumns(isMobileSized, comparisonType);
    }
    const columns: GridColDef[] = [];

    const valueFormatter = (id: string) => {
      return {
        valueFormatter: isMobileSized
          ? undefined
          : ({ value }: { value: AnyVal }) => {
              if (value === null) {
                return "-";
              }
              const percent = derivePercentCapacity(nodes[id].capacity, value);
              return `${formatNumberWithComma(value)} (${percent}%)`;
            },
      };
    };
    // Initial columns
    columns.push({
      field: "col1",
      headerName:
        comparisonType === ComparisonType.HourlyOccupancy ? "Hour" : "Date",
      type: "date",
      valueFormatter: (params) =>
        dateColFormatter(params, isMobileSized, comparisonType),
      ...deriveDefaultColumnProps(isMobileSized),
      align: "left",
      headerAlign: "left",
      renderHeader: undefined,
    });
    // Gather and push all average columns
    comparisonSpaceIds.forEach((id: string, i: number) => {
      columns.push({
        field: `average${i + 1}`,
        headerName: nodes[id].name,
        type: "number",
        ...valueFormatter(id),
        ...deriveDefaultColumnProps(isMobileSized),
        renderHeader: undefined,
      });
    });
    // Gather and push all peak columns
    comparisonSpaceIds.forEach((id: string, i: number) => {
      columns.push({
        field: `peak${i + 1}`,
        headerName: nodes[id].name,
        type: "number",
        ...valueFormatter(id),
        ...deriveDefaultColumnProps(isMobileSized),
        renderHeader: undefined,
      });
    });
    return columns;
  };

  const deriveColumnGroupingModel = (): GridColumnGroupingModel => {
    if (comparisonSpaceIds.length === 1) {
      return [];
    }
    const columnGroupingModel: GridColumnGroupingModel = [];
    const averageColumns: GridColDef[] = [];
    const peakColumns: GridColDef[] = [];
    const avgOccupancyAltText = deriveAvgOccupancyAltText(comparisonType);
    const peakOccupancyAltText = derivePeakOccupancyAltText(comparisonType);
    comparisonSpaceIds.forEach((id: string, i: number) => {
      averageColumns.push({ field: `average${i + 1}` });
      peakColumns.push({ field: `peak${i + 1}` });
    });
    columnGroupingModel.push(
      {
        groupId: "Average Occupancy",
        children: averageColumns,
        headerAlign: "center",
        renderHeaderGroup(params) {
          return (
            <HeaderWithTooltip
              tooltip={avgOccupancyAltText}
              headerName={params.headerName || ""}
            />
          );
        },
      },
      {
        groupId: "Peak Occupancy",
        children: peakColumns,
        headerAlign: "center",
        renderHeaderGroup(params) {
          return (
            <HeaderWithTooltip
              tooltip={peakOccupancyAltText}
              headerName={params.headerName || ""}
            />
          );
        },
      }
    );
    return columnGroupingModel;
  };

  const deriveRows = (): GridRowsProp => {
    const spaceDataOne = dailyData[comparisonSpaceIds[0]];
    if (!spaceDataOne) {
      return [];
    }
    if (comparisonSpaceIds.length === 1) {
      const rows: AnyVal = [];
      spaceDataOne.Avg.forEach((item: AnyVal, i: number) => {
        const capacity = nodes[comparisonSpaceIds[0]].capacity;
        const hasDate = availableDays.includes(item[0]);
        if (hasDate || comparisonType === ComparisonType.HourlyOccupancy) {
          rows.push({
            id: rows.length + 1,
            col1: item[0],
            average1: item[1],
            average2: derivePercentCapacity(capacity, spaceDataOne.Avg[i][1]),
            peak1: spaceDataOne.Peak[i][1],
            peak2: derivePercentCapacity(capacity, spaceDataOne.Peak[i][1]),
          });
        }
      });
      return rows;
    } else {
      const rows: AnyVal = [];
      spaceDataOne.Avg.forEach((item: AnyVal, i: number) => {
        const hasDate = availableDays.includes(item[0]);
        const row: AnyVal = {
          id: rows.length + 1,
          col1: item[0],
        };
        if (hasDate || comparisonType === ComparisonType.HourlyOccupancy) {
          // gather average rows
          comparisonSpaceIds.forEach((id: string, index: number) => {
            const spaceData = dailyData[id];
            row[`average${index + 1}`] = spaceData.Avg[i][1];
          });
          // gather peak rows
          comparisonSpaceIds.forEach((id: string, index: number) => {
            const spaceData = dailyData[id];
            row[`peak${index + 1}`] = spaceData.Peak[i][1];
          });
          rows.push(row);
        }
      });
      return rows;
    }
  };

  const columns = deriveSpacesColumns();
  const columnGroupingModel = deriveColumnGroupingModel();
  const rows = deriveRows();
  const columnVisibilityModel = deriveTableVisibilityModel(
    comparisonSpaceIds,
    comparisonDateRanges,
    tableShouldShowPeak,
    isMobileSized
  );
  return [rows, columns, columnGroupingModel, columnVisibilityModel];
};

const deriveDateTableToolTipAndValue = (
  parsedMetaData: AnyVal,
  isMobileSized: boolean,
  nodes: SpaceNodeByIdMap,
  spaceId: string,
  comparisonType?: ComparisonType
) => {
  const date = formatMediumDate(parsedMetaData.date as string);
  const type = parsedMetaData.type;
  if (parsedMetaData.value === null) {
    return {
      tooltip: `${date} ${type} Occupancy: -`,
      tableValue: "-",
    };
  }
  const valueString = isMobileSized
    ? formatNumberWithComma(parsedMetaData.value)
    : `${formatNumberWithComma(parsedMetaData.value)} (${derivePercentCapacity(
        nodes[spaceId].capacity,
        parsedMetaData.value
      )}%)`;
  if (comparisonType === ComparisonType.HourlyOccupancy) {
    return {
      tooltip: "",
      tableValue: parsedMetaData.value ? valueString : "-",
    };
  }
  return {
    tooltip: `${date} ${type} Occupancy: ${
      parsedMetaData.value ? valueString : "-"
    }`,
    tableValue: valueString,
  };
};

const deriveDateComparisonTableColumnProps = (
  nodes: SpaceNodeByIdMap,
  spaceId: string,
  isMobileSized: boolean,
  comparisonType?: ComparisonType
) => {
  return {
    sortComparator: (v1: string, v2: string) => {
      const parsedV1 = JSON.parse(v1);
      const parsedV2 = JSON.parse(v2);
      return parsedV1.value - parsedV2.value;
    },
    renderCell: (params: AnyVal) => {
      const parsedMetaData = JSON.parse(params.value as string);
      const { tooltip, tableValue } = deriveDateTableToolTipAndValue(
        parsedMetaData,
        isMobileSized,
        nodes,
        spaceId,
        comparisonType
      );
      return (
        <Tooltip title={tooltip}>
          <Typography
            sx={{
              fontSize: "inherit",
              color: "inherit",
              fontWeight: "inherit",
            }}
          >
            {tableValue}
          </Typography>
        </Tooltip>
      );
    },
  };
};

export const deriveTableDatesComparisonData = (
  spaceId: string,
  dateRange: AnyVal,
  availableDays: string[],
  tableShouldShowPeak: boolean,
  comparisonDateRanges: AnyVal[],
  parsedData: AnyVal,
  nodes: SpaceNodeByIdMap,
  isMobileSized: boolean,
  comparisonType?: ComparisonType
): [
  rows: GridRowsProp,
  columns: GridColDef[],
  columnGroupingModel: GridColumnGroupingModel,
  columnVisibilityModel: GridColumnVisibilityModel
] => {
  const datesToCompare = [dateRange, ...comparisonDateRanges];
  const dailyData = parsedData;

  const deriveDatesColumns = (): GridColDef[] => {
    const columns: GridColDef[] = [];
    // Initial columns
    columns.push({
      field: "col1",
      headerName:
        comparisonType === ComparisonType.HourlyOccupancy ? "Hour" : "Date",
      type: "date",
      ...deriveDefaultColumnProps(isMobileSized),
      valueFormatter: (params) =>
        dateColFormatter(params, isMobileSized, comparisonType),
      renderHeader: undefined,
    });
    // Gather and push all average columns
    datesToCompare.forEach((dateRange: DateRangeListItem, i: number) => {
      columns.push({
        field: `average${i + 1}`,
        headerName: deriveComparisonDatesLabel(
          dateRange.startDate,
          dateRange.endDate
        ),
        type: "number",
        valueFormatter: undefined,
        ...deriveDefaultColumnProps(isMobileSized),
        renderHeader: undefined,
        ...deriveDateComparisonTableColumnProps(
          nodes,
          spaceId,
          isMobileSized,
          comparisonType
        ),
      });
    });
    // Gather and push all peak columns
    datesToCompare.forEach((dateRange: DateRangeListItem, i: number) => {
      columns.push({
        field: `peak${i + 1}`,
        headerName: deriveComparisonDatesLabel(
          dateRange.startDate,
          dateRange.endDate
        ),
        type: "number",
        valueFormatter: undefined,
        ...deriveDefaultColumnProps(isMobileSized),
        renderHeader: undefined,
        ...deriveDateComparisonTableColumnProps(
          nodes,
          spaceId,
          isMobileSized,
          comparisonType
        ),
      });
    });
    return columns;
  };

  // TODO: make common for spaces and dates?
  const deriveColumnGroupingModel = (): GridColumnGroupingModel => {
    const columnGroupingModel: GridColumnGroupingModel = [];
    const averageColumns: GridColDef[] = [];
    const peakColumns: GridColDef[] = [];
    const avgOccupancyAltText = deriveAvgOccupancyAltText(comparisonType);
    const peakOccupancyAltText = derivePeakOccupancyAltText(comparisonType);
    datesToCompare.forEach((dateRange: DateRangeListItem, i: number) => {
      averageColumns.push({ field: `average${i + 1}` });
      peakColumns.push({ field: `peak${i + 1}` });
    });
    columnGroupingModel.push(
      {
        groupId: "Average Occupancy",
        children: averageColumns,
        headerAlign: "center",
        renderHeaderGroup(params) {
          return (
            <HeaderWithTooltip
              tooltip={avgOccupancyAltText}
              headerName={params.headerName || ""}
            />
          );
        },
      },
      {
        groupId: "Peak Occupancy",
        children: peakColumns,
        headerAlign: "center",
        renderHeaderGroup(params) {
          return (
            <HeaderWithTooltip
              tooltip={peakOccupancyAltText}
              headerName={params.headerName || ""}
            />
          );
        },
      }
    );
    return columnGroupingModel;
  };

  const deriveRows = (): GridRowsProp => {
    const dateOne = dailyData[0][spaceId];
    const rows: AnyVal = [];
    dateOne.Avg.forEach((item: AnyVal, i: number) => {
      const hasDate = availableDays.includes(item[0]);
      const row: AnyVal = {
        id: rows.length + 1,
        col1: item[0],
      };
      if (hasDate || comparisonType === ComparisonType.HourlyOccupancy) {
        // gather average rows
        datesToCompare.forEach(
          (dateRange: DateRangeListItem, index: number) => {
            const dateData = dailyData[index][spaceId];
            row[`average${index + 1}`] = JSON.stringify({
              date: dateData.Avg[i][0],
              value: dateData.Avg[i][1],
              type: ChartSeriesType.Average,
            });
          }
        );
        // gather peak rows
        datesToCompare.forEach(
          (dateRange: DateRangeListItem, index: number) => {
            const dateData = dailyData[index][spaceId];
            row[`peak${index + 1}`] = JSON.stringify({
              date: dateData.Peak[i][0],
              value: dateData.Peak[i][1],
              type: ChartSeriesType.Peak,
            });
          }
        );
        rows.push(row);
      }
    });
    return rows;
  };

  const columns = deriveDatesColumns();
  const columnGroupingModel = deriveColumnGroupingModel();
  const rows = deriveRows();
  const columnVisibilityModel = deriveTableVisibilityModel(
    [spaceId],
    comparisonDateRanges,
    tableShouldShowPeak,
    isMobileSized
  );
  return [rows, columns, columnGroupingModel, columnVisibilityModel];
};
