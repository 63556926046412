import React from "react";
import { AnyVal, SpaceNodeByIdMap } from "types";
import {
  deriveComparisonDatesLabel,
  formatMediumDate,
  formatMonthDayWithoutTrailingZero,
  formatMonthDayYear,
  formatMonthDayYearWithWeekday,
} from "../dates";
import { visitorsBarColors } from "../theme";
import { DateRangeListItem } from "./analytics-space-view";
import {
  GridColDef,
  GridColumnGroupingModel,
  GridColumnVisibilityModel,
  GridRowsProp,
  gridStringOrNumberComparator,
} from "@mui/x-data-grid";
import { formatNumberWithComma } from "../util/format";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { DateTime } from "luxon";
import { dataGridHeaderStyles } from "../theme";

export interface VisitorshipByHourChartData {
  [key: string]: {
    Total: [number, number][];
  };
}

export interface VisitorshipByHourResponse {
  chartData: VisitorshipByHourChartData;
  busiestHour: number;
  leastBusyHour: number;
  busiestTotal: number;
  leastBusyTotal: number;
  total: number;
}

export interface DailyVisitorsChartSeriesMetaDataItem {
  name: string; // name of the space
  seriesName: string; // name of the series (total)
  color: string; // color of the series
  id: number; // id of the space
  series: AnyVal; // data for the series
}

export const initialDailyVisitorsOptions: Highcharts.Options = {
  credits: {
    enabled: false,
  },
  chart: {
    type: "mixed",
    height: 300,
  },
  plotOptions: {
    column: {
      pointPadding: -0.1,
      opacity: 0.9,
    },
  },
  title: { text: undefined }, // hides the title
  yAxis: {
    title: { text: undefined },
  },
  xAxis: {
    // caps tickAmount at 15
    tickPositioner: function () {
      if ((this as AnyVal).dataMax === 0) {
        return [0];
      }
      const positions = [];
      let tick = Math.floor((this as AnyVal).dataMin);
      const increment = Math.ceil(
        ((this as AnyVal).dataMax - (this as AnyVal).dataMin) / 15
      );

      if (
        (this as AnyVal).dataMax !== null &&
        (this as AnyVal).dataMin !== null
      ) {
        for (
          tick;
          tick - increment <= (this as AnyVal).dataMax;
          tick += increment
        ) {
          positions.push(tick);
        }
      }
      return positions;
    },

    type: "category",
    labels: {
      formatter: function () {
        const dateString = JSON.parse((this as AnyVal).value).date;
        const date = new Date(dateString);
        return formatMonthDayWithoutTrailingZero(date.toISOString());
      },
    },
  },
  exporting: {
    enabled: false,
  },
};

const formatDailyVisitorsChartSpaceComparisonSeriesItem = (
  data: AnyVal,
  dataMax: number
) => {
  const newData = data.map((datum: AnyVal, i: number) => {
    if (datum[1] > dataMax) {
      dataMax = datum[1];
    }
    const x = i;
    return {
      x,
      y: datum[1],
      name: JSON.stringify({
        date: datum[0],
      }),
    };
  });
  return { newData, dataMax };
};

export const deriveColor = (
  oldChartItem: DailyVisitorsChartSeriesMetaDataItem | undefined,
  chartMetaData: DailyVisitorsChartSeriesMetaDataItem[]
) => {
  if (oldChartItem) {
    return oldChartItem.color;
  }
  for (let i = 0; i < visitorsBarColors.length; i++) {
    const color = visitorsBarColors[i];
    const colorAlreadyUsed = chartMetaData.find((item) => item.color === color);
    if (!colorAlreadyUsed) {
      return color;
    }
  }
  return visitorsBarColors[chartMetaData.length / 2];
};

export const deriveComparisonColumnSeries: AnyVal = (
  data: AnyVal,
  seriesName: string,
  color: string
) => {
  return {
    type: "column",
    name: seriesName,
    data,
    borderRadius: 4,
    color,
    showInLegend: false,
    visible: true,
  };
};

export const deriveDailyVisitorsChartDatesMetaData = (
  parsedData: AnyVal,
  nodes: SpaceNodeByIdMap,
  comparisonDateRanges: AnyVal[],
  dateRange: AnyVal,
  oldChartMetaData: DailyVisitorsChartSeriesMetaDataItem[]
) => {
  const dailyVisitorsSeriesMetaData: DailyVisitorsChartSeriesMetaDataItem[] =
    [];

  const datesToCompare: DateRangeListItem[] = [
    { ...dateRange, indexOfDateRange: 0 },
    ...comparisonDateRanges,
  ];

  let dataMax = 0;

  const xAxis: string[] = [];
  datesToCompare.forEach(({ startDate, indexOfDateRange, endDate }) => {
    const comparisonLabel = deriveComparisonDatesLabel(startDate, endDate);
    const spaceData = parsedData[indexOfDateRange];
    const formatDateData = (data: AnyVal, spaceName: string) => {
      return data.map((datum: AnyVal, i: number) => {
        if (datum[1] > dataMax) {
          dataMax = datum[1];
        }
        const x = i;
        return {
          x,
          y: datum[1],
          name: JSON.stringify({
            spaceName,
            date: datum[0],
          }),
        };
      });
    };
    const oldChartItem = oldChartMetaData.find(
      (item) => item.name === comparisonLabel
    );
    const color = deriveColor(oldChartItem, dailyVisitorsSeriesMetaData);

    Object.keys(spaceData).forEach((id) => {
      const chartData = spaceData[id];
      const node = nodes[id];

      chartData.Total.forEach((datum: AnyVal) => {
        xAxis.push(datum[0]);
      });

      dailyVisitorsSeriesMetaData.push({
        name: comparisonLabel,
        seriesName: comparisonLabel + " Total",
        color,
        id: node.id,
        series: deriveComparisonColumnSeries(
          formatDateData(chartData.Total, node.name + " Total"),
          comparisonLabel + " Total",
          color
        ),
      });
    });
  });
  return { dailyVisitorsSeriesMetaData, xAxis, dataMax };
};

export const deriveDailyVisitorsChartDatestooltip = () => {
  return {
    tooltip: {
      formatter: function () {
        const parsedMetaData = JSON.parse((this as AnyVal).key);
        const date = new Date(parsedMetaData.date);
        const spaceName = parsedMetaData.spaceName;
        const formattedDate = formatMonthDayYearWithWeekday(date.toISOString());
        return `<b>${formattedDate}</b><br />${spaceName} Total Visitors: ${
          (this as AnyVal).y
        }`;
      },
    },
  };
};

export const deriveDailyVisitorsChartSpacestooltip: AnyVal = () => {
  return {
    tooltip: {
      formatter: function () {
        const parsedMetaData = JSON.parse((this as AnyVal).key);
        const date = new Date(parsedMetaData.date);
        const formattedDate = formatMonthDayYearWithWeekday(date.toISOString());
        const visitors = (this as AnyVal).y;
        return `<b>${formattedDate}</b><br />${
          (this as AnyVal).series.name
        }: ${visitors}`;
      },
    },
  };
};

export const deriveDailyVisitorsChartXAxisOptions = (
  isDateComparison: boolean,
  xAxis: AnyVal[]
) => {
  if (isDateComparison) {
    return {
      xAxis: {
        labels: {
          formatter: function (value: AnyVal) {
            const date = new Date(xAxis[value.pos]);
            return DateTime.fromISO(date.toISOString())
              .toUTC()
              .get("weekdayShort");
          },
        },
      },
    };
  }
  return {
    xAxis: {
      labels: {
        formatter: function () {
          // TODO: remove below and check for a better solution
          // const dateString = JSON.parse((this as AnyVal).value).date;

          const data = (this as AnyVal)?.chart?.options?.series[0].data[
            (this as AnyVal).pos
          ];
          if (data) {
            const dateString = JSON.parse(data.name).date;
            const date = new Date(dateString);
            return formatMonthDayWithoutTrailingZero(date.toISOString());
          }
          return "";
        },
      },
    },
  };
};

export const deriveDailyVisitorsChartSpacesMetaData = (
  parsedData: AnyVal[],
  nodes: SpaceNodeByIdMap,
  comparisonSpaceIds: string[],
  oldChartMetaData: DailyVisitorsChartSeriesMetaDataItem[]
) => {
  const dayData = parsedData[0];
  if (!dayData) {
    return { dailyVisitorsSeriesMetaData: [], xAxis: [], dataMax: 0 };
  }
  const dailyVisitorsSeriesMetaData: DailyVisitorsChartSeriesMetaDataItem[] =
    [];
  const dataMax = 0;
  comparisonSpaceIds.forEach((id: string) => {
    const chartData = dayData[id];
    const node = nodes[id];
    const oldChartItem = oldChartMetaData.find((item) => item.id === node.id);
    const color = deriveColor(oldChartItem, dailyVisitorsSeriesMetaData);

    if (!chartData) {
      return { dailyVisitorsSeriesMetaData: [], xAxis: [], dataMax: 0 };
    }

    const { newData: formattedTotal } =
      formatDailyVisitorsChartSpaceComparisonSeriesItem(
        chartData.Total,
        dataMax
      );

    dailyVisitorsSeriesMetaData.push({
      name: node.name,
      seriesName: node.name + " Total Visitors",
      color,
      id: node.id,
      series: deriveComparisonColumnSeries(
        formattedTotal,
        node.name + " Total Visitors",
        color
      ),
    });
  });
  const xAxis: AnyVal[] = [];
  return { dailyVisitorsSeriesMetaData, xAxis, dataMax };
};

// TODO: common
export const deriveColumnPaddingOptions = (hasComparison: boolean) => {
  if (hasComparison) {
    return {
      plotOptions: {
        column: {
          pointPadding: 0,
        },
      },
    };
  }
  // Decreases the padding to make the columns wider
  return {
    plotOptions: {
      column: {
        pointPadding: -0.1,
      },
    },
  };
};

export const deriveDailyVisitorsChartOptions = (
  parsedData: AnyVal,
  isDateComparison: boolean,
  options: Highcharts.Options,
  nodes: SpaceNodeByIdMap,
  comparisonSpaceIds: string[],
  comparisonDateRanges: AnyVal[],
  chartMetaData: DailyVisitorsChartSeriesMetaDataItem[],
  dateRange: AnyVal
) => {
  const { dailyVisitorsSeriesMetaData, xAxis, dataMax } = isDateComparison
    ? deriveDailyVisitorsChartDatesMetaData(
        parsedData,
        nodes,
        comparisonDateRanges,
        dateRange,
        chartMetaData
      )
    : deriveDailyVisitorsChartSpacesMetaData(
        parsedData,
        nodes,
        comparisonSpaceIds,
        chartMetaData
      );
  const series = dailyVisitorsSeriesMetaData.map((item) => item.series);

  const deriveToolTip = () => {
    if (isDateComparison) {
      return deriveDailyVisitorsChartDatestooltip();
    }
    return deriveDailyVisitorsChartSpacestooltip();
  };

  const toolTip = deriveToolTip();

  const hasComparison = Boolean(
    comparisonDateRanges.length > 0 || comparisonSpaceIds.length > 1
  );

  const xAxisOptions = deriveDailyVisitorsChartXAxisOptions(
    isDateComparison,
    xAxis
  );

  const newOptions = {
    ...options,
    ...deriveColumnPaddingOptions(hasComparison),
    ...toolTip,
    ...xAxisOptions,
    series,
  };
  return { newOptions, dailyVisitorsSeriesMetaData, dataMax };
};

// =================================================================================================
// Table stuff
// =================================================================================================

const deriveDefaultColumnProps = (
  isMobileSized: boolean
): Partial<GridColDef> => {
  return {
    flex: 1,
    align: "left",
    headerAlign: "left",
    sortable: !isMobileSized, // Removes sort on mobile
    sortComparator: gridStringOrNumberComparator,
    renderCell: undefined,
  };
};

const dateColFormatter = (params: AnyVal, isMobileSized: boolean) => {
  return isMobileSized
    ? formatMonthDayYear(params.value as string)
    : DateTime.fromISO(params.value as string).toLocaleString(
        DateTime.DATE_MED
      );
};

const deriveDefaultDailyColumns = (isMobileSized: boolean): GridColDef[] => {
  return [
    {
      field: "col1",
      headerName: "Date",
      type: "date",
      ...deriveDefaultColumnProps(isMobileSized),
      valueFormatter: (params) => dateColFormatter(params, isMobileSized),
      minWidth: 90,
    },
    {
      field: "total1",
      headerName: "Visitors",
      type: "number",
      ...deriveDefaultColumnProps(isMobileSized),
      valueFormatter: ({ value }: { value: AnyVal }) => {
        if (value === null) {
          return "-";
        }
        return `${formatNumberWithComma(value)}`;
      },
      renderHeader(params) {
        return (
          <Tooltip title="The total visitors to this space for the date indicated">
            <Typography sx={{ ...dataGridHeaderStyles }}>
              {params.colDef.headerName}
            </Typography>
          </Tooltip>
        );
      },
    },
  ];
};

export const deriveDailyVisitorsTableSpacesComparisonData = (
  dateRange: AnyVal,
  availableDays: string[],
  comparisonDateRanges: AnyVal[],
  isDateComparison: boolean,
  comparisonSpaceIds: string[],
  parsedData: AnyVal,
  nodes: SpaceNodeByIdMap,
  isMobileSized: boolean
): [
  rows: GridRowsProp,
  columns: GridColDef[],
  columnGroupingModel: GridColumnGroupingModel,
  columnVisibilityModel: GridColumnVisibilityModel
] => {
  if (isDateComparison) {
    // TODO: clean this func up
    return deriveTableDatesComparisonData(
      comparisonSpaceIds[0],
      dateRange,
      availableDays,
      comparisonDateRanges,
      parsedData,
      nodes,
      isMobileSized
    );
  }
  const dailyData = parsedData[0];
  if (!dailyData) {
    return [[], [], [], {}];
  }

  const deriveSpacesColumns = (): GridColDef[] => {
    if (comparisonSpaceIds.length === 1) {
      return deriveDefaultDailyColumns(isMobileSized);
    }
    const columns: GridColDef[] = [];

    const valueFormatter = () => {
      return {
        valueFormatter: ({ value }: { value: AnyVal }) => {
          if (value === null) {
            return "-";
          }
          return `${formatNumberWithComma(value)}`;
        },
      };
    };
    // Initial columns
    columns.push({
      field: "col1",
      headerName: "Date",
      type: "date",
      valueFormatter: (params) => dateColFormatter(params, isMobileSized),
      ...deriveDefaultColumnProps(isMobileSized),
      renderHeader: undefined,
    });
    // Gather and push all total columns
    comparisonSpaceIds.forEach((id: string, i: number) => {
      columns.push({
        field: `total${i + 1}`,
        headerName: nodes[id].name,
        type: "number",
        ...valueFormatter(),
        ...deriveDefaultColumnProps(isMobileSized),
        renderHeader: undefined,
      });
    });
    return columns;
  };

  const deriveColumnGroupingModel = (): GridColumnGroupingModel => {
    if (comparisonSpaceIds.length === 1) {
      return [];
    }
    const columnGroupingModel: GridColumnGroupingModel = [];
    const totalColumns: GridColDef[] = [];
    comparisonSpaceIds.forEach((id: string, i: number) => {
      totalColumns.push({ field: `total${i + 1}` });
    });
    columnGroupingModel.push({
      groupId: "Total Visitors",
      children: totalColumns,
      headerAlign: "center",
      renderHeaderGroup(params) {
        return (
          <Tooltip title="The total visitors to this space for the date indicated">
            <Typography sx={{ ...dataGridHeaderStyles }}>
              {params.headerName}
            </Typography>
          </Tooltip>
        );
      },
    });
    return columnGroupingModel;
  };

  const deriveRows = (): GridRowsProp => {
    const spaceDataOne = dailyData[comparisonSpaceIds[0]];
    if (!spaceDataOne) {
      return [];
    }
    if (comparisonSpaceIds.length === 1) {
      const rows: AnyVal = [];
      spaceDataOne.Total.forEach((item: AnyVal) => {
        const hasDate = availableDays.includes(item[0]);
        if (hasDate) {
          rows.push({
            id: rows.length + 1,
            col1: item[0],
            total1: item[1],
          });
        }
      });
      return rows;
    } else {
      const rows: AnyVal = [];
      spaceDataOne.Total.forEach((item: AnyVal, i: number) => {
        const hasDate = availableDays.includes(item[0]);
        const row: AnyVal = {
          id: rows.length + 1,
          col1: item[0],
        };
        if (hasDate) {
          // gather total rows
          comparisonSpaceIds.forEach((id: string, index: number) => {
            const spaceData = dailyData[id];
            row[`total${index + 1}`] = spaceData.Total[i][1];
          });

          rows.push(row);
        }
      });
      return rows;
    }
  };

  const columns = deriveSpacesColumns();
  const columnGroupingModel = deriveColumnGroupingModel();
  const rows = deriveRows();
  const columnVisibilityModel = {};
  return [rows, columns, columnGroupingModel, columnVisibilityModel];
};

const deriveDateTableToolTipAndValue = (parsedMetaData: AnyVal) => {
  const date = formatMediumDate(parsedMetaData.date as string);
  if (parsedMetaData.value === null) {
    return {
      tooltip: `${date} Total: -`,
      tableValue: "-",
    };
  }
  const valueString = formatNumberWithComma(parsedMetaData.value);
  return {
    tooltip: `${date} Total: ${valueString}`,
    tableValue: valueString,
  };
};

const deriveDateComparisonTableColumnProps = () => {
  return {
    sortComparator: (v1: string, v2: string) => {
      const parsedV1 = JSON.parse(v1);
      const parsedV2 = JSON.parse(v2);
      return parsedV1.value - parsedV2.value;
    },
    renderCell: (params: AnyVal) => {
      const parsedMetaData = JSON.parse(params.value as string);
      const { tooltip, tableValue } =
        deriveDateTableToolTipAndValue(parsedMetaData);
      return (
        <Tooltip title={tooltip}>
          <Typography
            sx={{
              fontSize: "inherit",
              color: "inherit",
              fontWeight: "inherit",
            }}
          >
            {tableValue}
          </Typography>
        </Tooltip>
      );
    },
  };
};

export const deriveTableDatesComparisonData = (
  spaceId: string,
  dateRange: AnyVal,
  availableDays: string[],
  comparisonDateRanges: AnyVal[],
  parsedData: AnyVal,
  nodes: SpaceNodeByIdMap,
  isMobileSized: boolean
): [
  rows: GridRowsProp,
  columns: GridColDef[],
  columnGroupingModel: GridColumnGroupingModel,
  columnVisibilityModel: GridColumnVisibilityModel
] => {
  const datesToCompare = [dateRange, ...comparisonDateRanges];
  const dailyData = parsedData;

  const deriveDatesColumns = (): GridColDef[] => {
    const columns: GridColDef[] = [];
    // Initial columns
    columns.push({
      field: "col1",
      headerName: "Date",
      type: "date",
      ...deriveDefaultColumnProps(isMobileSized),
      valueFormatter: (params) => dateColFormatter(params, isMobileSized),
      renderHeader: undefined,
    });
    // Gather and push all total columns
    datesToCompare.forEach((dateRange: DateRangeListItem, i: number) => {
      columns.push({
        field: `total${i + 1}`,
        headerName: deriveComparisonDatesLabel(
          dateRange.startDate,
          dateRange.endDate
        ),
        type: "number",
        ...deriveDefaultColumnProps(isMobileSized),
        renderHeader: undefined,
        valueFormatter: undefined,
        ...deriveDateComparisonTableColumnProps(),
      });
    });
    return columns;
  };

  // TODO: make common for spaces and dates?
  const deriveColumnGroupingModel = (): GridColumnGroupingModel => {
    const columnGroupingModel: GridColumnGroupingModel = [];
    const totalColumns: GridColDef[] = [];
    datesToCompare.forEach((dateRange: DateRangeListItem, i: number) => {
      totalColumns.push({ field: `total${i + 1}` });
    });
    columnGroupingModel.push({
      groupId: "Total Visitors",
      children: totalColumns,
      headerAlign: "center",
      renderHeaderGroup(params) {
        return (
          <Tooltip title="The total visitors to this space for the date indicated">
            <Typography sx={{ ...dataGridHeaderStyles }}>
              {params.headerName}
            </Typography>
          </Tooltip>
        );
      },
    });
    return columnGroupingModel;
  };

  const deriveRows = (): GridRowsProp => {
    const dateOne = dailyData[0][spaceId];
    const rows: AnyVal = [];
    dateOne.Total.forEach((item: AnyVal, i: number) => {
      const hasDate = availableDays.includes(item[0]);
      const row: AnyVal = {
        id: rows.length + 1,
        col1: item[0],
      };
      if (hasDate) {
        datesToCompare.forEach(
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          (dateRange: DateRangeListItem, index: number) => {
            const dateData = dailyData[index][spaceId];
            row[`total${index + 1}`] = JSON.stringify({
              date: dateData.Total[i][0],
              value: dateData.Total[i][1],
            });
          }
        );
        rows.push(row);
      }
    });
    return rows;
  };

  const columns = deriveDatesColumns();
  const columnGroupingModel = deriveColumnGroupingModel();
  const rows = deriveRows();
  const columnVisibilityModel = {};
  return [rows, columns, columnGroupingModel, columnVisibilityModel];
};
