import HighchartsReact from "highcharts-react-official";
import { barColors } from "../theme";
import { ChartSeriesMetaDataItem } from "./datechart";

export const deriveChartYAxisMax = (
  isYAxisPercent: boolean,
  isYAxisZoomed: boolean,
  capacity: number,
  max: number | null
) => {
  if (isYAxisPercent) {
    if (isYAxisZoomed) {
      return undefined;
    }
    return 100;
  }
  let yAxisMax: number | undefined = undefined;
  const isMaxGreaterThanCapacity = max && max > capacity;
  if (!isYAxisZoomed) {
    yAxisMax = isMaxGreaterThanCapacity ? max : capacity;
  }
  return yAxisMax;
};

export const deriveColor = (
  oldChartItem: ChartSeriesMetaDataItem | undefined,
  chartMetaData: ChartSeriesMetaDataItem[]
) => {
  if (oldChartItem) {
    return oldChartItem.color;
  }
  for (let i = 0; i < barColors.length; i++) {
    const color = barColors[i];
    const colorAlreadyUsed = chartMetaData.find((item) => item.color === color);
    if (!colorAlreadyUsed) {
      return color;
    }
  }
  return barColors[chartMetaData.length / 2];
};

export const chartReflow = (
  ref: React.RefObject<HighchartsReact.RefObject>
) => {
  if (ref.current) {
    const sleep = (ms: number) => {
      return new Promise((resolve) => setTimeout(resolve, ms));
    };
    if (ref.current.chart) {
      sleep(500).then(() => {
        ref.current?.chart?.reflow();
      });
    }
  }
};
