import Highcharts from "highcharts";
import { formatMonthDayWithoutTrailingZero } from "../dates";
import { formatNumberWithComma } from "../util/format";
import { AnyVal } from "../interfaces";

export interface OccupancyByDate {
  Date: string;
  AvgOccupancy: number;
  PeakOccupancy: number;
}

export const initialDateOptions: Highcharts.Options = {
  credits: {
    enabled: false,
  },
  chart: {
    type: "mixed",
    height: 300,
  },
  plotOptions: {
    column: {
      pointPadding: -0.1,
      opacity: 0.9,
    },
  },
  title: { text: undefined }, // hides the title
  xAxis: {
    // caps tickAmount at 15
    tickPositioner: function () {
      if ((this as AnyVal).dataMax === 0) {
        return [0];
      }
      const positions = [];
      let tick = Math.floor((this as AnyVal).dataMin);
      const increment = Math.ceil(
        ((this as AnyVal).dataMax - (this as AnyVal).dataMin) / 15
      );

      if (
        (this as AnyVal).dataMax !== null &&
        (this as AnyVal).dataMin !== null
      ) {
        for (
          tick;
          tick - increment <= (this as AnyVal).dataMax;
          tick += increment
        ) {
          positions.push(tick);
        }
      }
      return positions;
    },

    type: "category",
    labels: {
      formatter: function () {
        const dateString = JSON.parse((this as AnyVal).value).date;
        const date = new Date(dateString);
        return formatMonthDayWithoutTrailingZero(date.toISOString());
      },
    },
  },
  exporting: {
    enabled: false,
  },
};

const deriveTickPositions = (max: number) => {
  const positions = [0];
  // position 1 will be 1/4 of the way to the next max
  const position1 = Math.ceil(max / 4);
  positions.push(position1);
  // position 2 will be 1/2 of the way to the next max
  const position2 = Math.ceil(max / 2);
  positions.push(position2);
  // position 3 will be 3/4 of the way to the next max
  const position3 = Math.ceil((3 * max) / 4);
  positions.push(position3);
  // position 4 will be the next max
  positions.push(max);
  return positions;
};

export const deriveDefaultYAxisOptions = (
  yAxisMax: number | undefined,
  capacity: number,
  showSecondYAxis: boolean,
  isYAxisZoomed: boolean,
  isYAxisPercentage?: boolean,
  showEarlyPreview?: boolean
) => {
  const positionProps =
    yAxisMax && !isYAxisZoomed
      ? ({ tickPositioner: () => deriveTickPositions(yAxisMax) } as AnyVal)
      : { tickPositioner: undefined };

  // we want to remove the y axis entirely
  if (showEarlyPreview) {
    return {
      yAxis: {
        visible: false,
      },
    };
  }
  if (showSecondYAxis) {
    return {
      yAxis: [
        {
          max: yAxisMax,
          title: { text: undefined }, // hides the title
          ...positionProps,
          allowDecimals: false,
          labels: {
            formatter: function (value: AnyVal) {
              return formatNumberWithComma(value.value);
            },
          },
        },
        {
          title: { text: undefined }, // hides the title
          max: capacity,
          opposite: true,
          linkedTo: 0,
          labels: {
            formatter: function (value: AnyVal) {
              if (value.value === null || capacity === 0) {
                return "-";
              }
              return `${Math.round((100 * value.value) / capacity)}%`;
            },
          },
          ...positionProps,
          allowDecimals: false,
        },
      ],
    };
  }
  return {
    yAxis: {
      max: yAxisMax,
      title: { text: undefined }, // hides the title
      allowDecimals: false,
      ...positionProps,
      labels: {
        formatter: function (value: AnyVal) {
          if (isYAxisPercentage) {
            if (value.value === null) {
              return "-";
            }
            return `${Math.round(value.value)}%`;
          }
          return formatNumberWithComma(value.value);
        },
      },
    },
  };
};

export const deriveDefaultOptions = (
  options: Highcharts.Options,
  series: Highcharts.Options["series"],
  overrides?: Partial<Highcharts.Options>
): Highcharts.Options => {
  return {
    ...options,
    series,
    ...overrides,
  };
};

export const parseDefaultData = (
  data: AnyVal,
  shouldDisplayPeak: boolean,
  capacity: number
) => {
  const parsedOccupancyByDate = JSON.parse(data);

  const averages: AnyVal = [];
  const peaks: AnyVal = [];
  let max = capacity;
  let allAveragesZero = true;
  let allPeaksZero = true;

  parsedOccupancyByDate.forEach(
    ({ Date: OccuDate, AvgOccupancy, PeakOccupancy }: OccupancyByDate) => {
      const jsDate = new Date(OccuDate);
      const dateMs = jsDate.getTime();
      averages.push([dateMs, AvgOccupancy]);
      peaks.push([dateMs, PeakOccupancy]);
      const maxToCheck = shouldDisplayPeak ? PeakOccupancy : AvgOccupancy;
      if (maxToCheck > max) {
        max = maxToCheck;
      }
      if (AvgOccupancy !== 0) {
        allAveragesZero = false;
      }
      if (PeakOccupancy !== 0) {
        allPeaksZero = false;
      }
    }
  );
  return { averages, peaks, max, allAveragesZero, allPeaksZero };
};

export const deriveYAxisMax = (
  isYAxisZoomed: boolean,
  shouldDisplayAverage: boolean,
  shouldDisplayPeak: boolean,
  allAveragesZero: boolean,
  allPeaksZero: boolean,
  max: number
) => {
  const showingOnlyAverage = shouldDisplayAverage && !shouldDisplayPeak;
  const showingOnlyPeak = shouldDisplayPeak && !shouldDisplayAverage;
  let yAxisMax: number | undefined = undefined;
  if (!isYAxisZoomed) {
    yAxisMax = max;
  }
  if (
    (showingOnlyAverage && allAveragesZero) ||
    (showingOnlyPeak && allPeaksZero)
  ) {
    yAxisMax = 1;
  }
  return yAxisMax;
};

export interface SeriesMetaDataItem {
  name: string;
  color: string;
  id: string;
}

export enum ChartSeriesType {
  Average = "Average",
  Peak = "Peak",
}

export interface ChartSeriesMetaDataItem {
  name: string; // name of the space
  seriesName: string; // name of the series (average or peak)
  color: string; // color of the series
  id: number; // id of the space
  type: ChartSeriesType; // average or peak
  series: AnyVal; // data for the series
  percentageSeries: AnyVal; // data for the percentage series
}
