import {
  heatmapPurple100,
  greyThree,
  heatmapPurple1700,
  purple,
  dataGridHeaderStyles,
  greyOne,
  greyFive,
  greyNine,
} from "../theme";
import { AnyVal, DaysOfTheWeek } from "../interfaces";
import { formatPercentage, formatToTwelveHour } from "../numbers";
import { formatNumberWithComma } from "../util/format";
import { GridColDef, GridRowsProp } from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

export interface OccupancyByDWeekdayAndHour {
  Weekday: string;
  Hour: number;
  AvgOccupancy: number;
}

const fontFamily = `Nunito,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";`;

// Used for Y-axis labels
const WEEK_DAYS = ["Sun", "Mon", "Tues", "Wed", "Thur", "Fri", "Sat"];

const deriveWeekdayNum = (weekday: string) => {
  switch (weekday) {
    case DaysOfTheWeek.Sunday:
      return 0;
    case DaysOfTheWeek.Monday:
      return 1;
    case DaysOfTheWeek.Tuesday:
      return 2;
    case DaysOfTheWeek.Wednesday:
      return 3;
    case DaysOfTheWeek.Thursday:
      return 4;
    case DaysOfTheWeek.Friday:
      return 5;
    case DaysOfTheWeek.Saturday:
      return 6;
    default:
      return 0;
  }
};

export const deriveHeatmapSeries = (
  parsedData: OccupancyByDWeekdayAndHour[]
) => {
  if (parsedData.length === 0) {
    return [];
  }
  return parsedData.map((datum: OccupancyByDWeekdayAndHour) => {
    return [datum.Hour, deriveWeekdayNum(datum.Weekday), datum.AvgOccupancy];
  });
};

export const initialHeatmapOptions = (
  showEarlyPreview: boolean
): Highcharts.Options => {
  return {
    legend: {
      symbolWidth: 400,
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    chart: {
      type: "heatmap",
      height: 280,
    },
    title: {
      text: undefined,
    },
    xAxis: {
      // Set X-axis value, we need 24h
      min: 0,
      max: 23,
      // Set the tickInterval to 1, to show all 24h on X-axis
      tickInterval: 1,
      labels: {
        step: 1,
        style: {
          fontFamily,
        },
        formatter: function (value: AnyVal) {
          return formatToTwelveHour(value.value);
        },
      },
      gridLineWidth: 0,
      lineWidth: 1,
      lineColor: greyThree,
      tickWidth: 1,
      tickLength: 6,
      tickColor: greyThree,
      title: {
        text: undefined,
      },
    },
    yAxis: {
      reversed: true,
      categories: WEEK_DAYS,
      title: {
        text: undefined,
      },
      labels: {
        style: {
          fontSize: "14px",
          fontFamily,
        },
      },
    },
    colorAxis: {
      visible: showEarlyPreview ? false : true,
      stops: showEarlyPreview
        ? [
            [0, greyOne],
            [0.5, greyFive],
            [1, greyNine],
          ]
        : [
            [0, heatmapPurple100],
            [0.5, purple],
            [1, heatmapPurple1700],
          ],
    },
  };
};

export const deriveHeatmapTooltip = (
  capacity: number,
  showEarlyPreview: boolean
) => {
  if (showEarlyPreview) {
    return {
      tooltip: {
        formatter: function () {
          const weekDay = WEEK_DAYS[(this as AnyVal).point.y];
          return (
            "<b>" +
            weekDay +
            ", " +
            formatToTwelveHour((this as AnyVal).point.x) +
            "</b>"
          );
        },
      },
    };
  }
  return {
    tooltip: {
      formatter: function () {
        const percent = Math.floor(
          ((this as AnyVal).point.value / capacity) * 100
        );
        const weekDay = WEEK_DAYS[(this as AnyVal).point.y];
        return (
          "<b>" +
          weekDay +
          ", " +
          formatToTwelveHour((this as AnyVal).point.x) +
          "</b>" +
          "<br />" +
          "Average Occupancy: " +
          formatNumberWithComma((this as AnyVal).point.value) +
          ` (${percent}%)`
        );
      },
    },
  };
};

const deriveAltText = (weekday: string) => {
  return `The average occupancy of this space for the hour indicated on ${weekday}s, over the date and time range set above`;
};

export const deriveHeatmapTableData = (
  parsedData: OccupancyByDWeekdayAndHour[],
  capacity: number
): [rows: GridRowsProp, columns: GridColDef[]] => {
  if (!parsedData || !parsedData.length) {
    return [[], []];
  }
  const columns: GridColDef[] = [];
  // I have an array of objects that looks like this: {Weekday: 'Sunday', Hour: 0, AvgOccupancy: 91}
  // I need to transform it into an array of objects that looks like this: {id: Hour, Monday: AvgOccupancy, Tuesday: AvgOccupancy, ...}
  // first, I need to get the unique hours in parsedData
  const hours = parsedData.map((datum) => datum.Hour);
  const uniqueHours = [...new Set(hours)];
  // next, I need to get the unique weekdays in parsedData
  const weekdays = parsedData.map((datum) => datum.Weekday);
  const uniqueWeekdays = [...new Set(weekdays)];
  // next, I need to create an array of objects that looks like this: {id: Hour, Monday: 0, Tuesday: 0, ...}
  const rows: GridRowsProp = [];
  uniqueHours.forEach((hour) => {
    const row: AnyVal = { id: hour, Hour: formatToTwelveHour(hour) };
    uniqueWeekdays.forEach((weekday) => {
      // find the datum that matches the hour and weekday
      const datum = parsedData.find(
        (datum) => datum.Hour === hour && datum.Weekday === weekday
      );
      row[weekday] = datum ? datum.AvgOccupancy : 0;
    });
    (rows as AnyVal).push(row);
  });
  // create columns for unique weekdays
  columns.push({
    field: "Hour",
    headerName: "Hour",
    type: "string",
    align: "left",
    headerAlign: "left",
    flex: 1,
  });
  uniqueWeekdays.forEach((weekday) => {
    columns.push({
      field: weekday,
      headerName: weekday,
      type: "number",
      align: "left",
      headerAlign: "left",
      flex: 1,
      valueFormatter: ({ value }: { value: number }) => {
        return `${formatNumberWithComma(value)} (${formatPercentage(
          value,
          capacity
        )})`;
      },
      renderHeader(params) {
        return (
          <Tooltip title={deriveAltText(weekday)}>
            <Typography sx={{ ...dataGridHeaderStyles }}>
              {params.colDef.headerName}
            </Typography>
          </Tooltip>
        );
      },
    });
  });

  return [rows, columns];
};
