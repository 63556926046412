import { AnyVal, SpaceNodeByIdMap } from "../interfaces";
import {
  ChartSeriesMetaDataItem,
  ChartSeriesType,
  deriveDefaultYAxisOptions,
} from "./datechart";
import { deriveChartYAxisMax, deriveColor } from "./charts-common";
import { deriveComparisonDatesLabel } from "../dates";
import { formatToTwelveHour } from "../numbers";
import { formatNumberWithComma } from "../util/format";
import { DateRangeListItem } from "./analytics-space-view";

export const deriveHourLineSeries: AnyVal = (
  data: AnyVal,
  seriesName: string,
  color: string,
  shouldDisplayAverage: boolean
) => {
  return {
    showInLegend: false,
    lineWidth: 4,
    marker: {
      lineColor: "white",
      lineWidth: 2,
      symbol: "circle",
      radius: 5,
    },
    type: "line",
    name: seriesName,
    data,
    color,
    visible: shouldDisplayAverage,
  };
};

export const deriveHourScatterSeries: AnyVal = (
  data: AnyVal,
  seriesName: string,
  color: string,
  shouldDisplayPeak: boolean
) => {
  return {
    type: "scatter",
    name: seriesName,
    data,
    color,
    lineWidth: 0,
    marker: {
      symbol: "diamond",
      lineColor: "black",
      lineWidth: 1,
      radius: 3,
    },
    showInLegend: false,
    visible: shouldDisplayPeak,
  };
};

export const deriveHourSeriesDatestooltip: AnyVal = (capacity: number) => {
  return {
    tooltip: {
      formatter: function () {
        const { x, y } = (this as AnyVal).point;
        const hour = formatToTwelveHour(x);
        const occupancy = formatNumberWithComma(y);
        const percentage = Math.round((100 * y) / capacity);
        return `<b>${hour}</b><br/>${
          (this as AnyVal).series.name
        } Occupancy: ${occupancy} (${percentage}%)`;
      },
    },
  };
};

export const deriveEarlyPreviewHourChartTooltip: AnyVal = () => {
  return {
    tooltip: {
      formatter: function () {
        const { x } = (this as AnyVal).point;
        const hour = formatToTwelveHour(x);
        return `<b>${hour}</b>`;
      },
    },
  };
};

export const deriveHourChartSpacestooltip: AnyVal = (showPercent: boolean) => {
  return {
    tooltip: {
      formatter: function () {
        const { x, y } = (this as AnyVal).point;
        const hour = formatToTwelveHour(x);
        const occupancy = formatNumberWithComma(y);
        const parsedMetaData = JSON.parse((this as AnyVal).key);
        const percentage = parsedMetaData.percentOccupancy;
        const text = showPercent
          ? `${occupancy} (${percentage}%)`
          : `${occupancy}`;
        return `<b>${hour}</b><br />${
          (this as AnyVal).series.name
        } Occupancy: ${text}`;
      },
    },
  };
};

export const deriveHourChartSpacesPercentagetooltip: AnyVal = () => {
  return {
    tooltip: {
      formatter: function () {
        const { x, y } = (this as AnyVal).point;
        const hour = formatToTwelveHour(x);
        return `<b>${hour}</b><br />${
          (this as AnyVal).series.name
        } Percent Occupancy: ${y}%`;
      },
    },
  };
};

const formatHourChartSpaceComparisonSeriesItem = (
  data: AnyVal,
  capacity: number,
  dataMax: number
) => {
  const newData = data.map((datum: AnyVal) => {
    if (datum[1] > dataMax) {
      dataMax = datum[1];
    }
    return {
      x: datum[0],
      y: datum[1],
      name: JSON.stringify({
        percentOccupancy: Math.round((100 * datum[1]) / capacity),
      }),
    };
  });
  return { newData, dataMax };
};

const formatHourChartSpaceComparisonSeriesItemPercent = (
  data: AnyVal,
  capacity: number,
  dataMax: number
) => {
  const newData = data.map((datum: AnyVal) => {
    if (datum[1] > dataMax) {
      dataMax = datum[1];
    }
    const percent = Math.round((100 * datum[1]) / capacity);
    return {
      x: datum[0],
      y: percent,
      name: JSON.stringify({
        percentOccupancy: percent,
      }),
    };
  });
  return { newData, dataMax };
};

export const deriveHourChartSpacesMetaData = (
  parsedData: AnyVal[],
  nodes: SpaceNodeByIdMap,
  comparisonSpaceIds: string[],
  chartMetaData: ChartSeriesMetaDataItem[],
  shouldDisplayAverage: boolean,
  shouldDisplayPeak: boolean
) => {
  const hourData = parsedData[0];
  if (!hourData) {
    return { hourSeriesMetaData: [], dataMax: 0 };
  }
  const hourSeriesMetaData: ChartSeriesMetaDataItem[] = [];
  let dataMax = 0;
  comparisonSpaceIds.forEach((id) => {
    const chartData = hourData[id];
    const node = nodes[Number(id)];
    const capacity = node.capacity;
    const oldChartItem = chartMetaData.find((item) => item.id === node.id);
    const color = deriveColor(oldChartItem, hourSeriesMetaData);

    if (!chartData) {
      return { hourSeriesMetaData: [], dataMax: 0 };
    }

    const { newData: averageData } = formatHourChartSpaceComparisonSeriesItem(
      chartData.Avg,
      capacity,
      dataMax
    );
    const { newData: averageDataPercent } =
      formatHourChartSpaceComparisonSeriesItemPercent(
        chartData.Avg,
        capacity,
        dataMax
      );
    const { newData: peakData, dataMax: newMax } =
      formatHourChartSpaceComparisonSeriesItem(
        chartData.Peak,
        capacity,
        dataMax
      );
    const { newData: peakDataPercent } =
      formatHourChartSpaceComparisonSeriesItemPercent(
        chartData.Peak,
        capacity,
        dataMax
      );
    dataMax = newMax;

    hourSeriesMetaData.push({
      name: node.name,
      seriesName: node.name + " Average",
      color,
      id: node.id,
      type: ChartSeriesType.Average,
      series: deriveHourLineSeries(
        averageData,
        node.name + " Average",
        color,
        shouldDisplayAverage
      ),
      percentageSeries: deriveHourLineSeries(
        averageDataPercent,
        node.name + " Average",
        color,
        shouldDisplayAverage
      ),
    });
    hourSeriesMetaData.push({
      name: node.name,
      seriesName: node.name + " Peak",
      color,
      id: node.id,
      type: ChartSeriesType.Peak,
      series: deriveHourScatterSeries(
        peakData,
        node.name + " Peak",
        color,
        shouldDisplayPeak
      ),
      percentageSeries: deriveHourScatterSeries(
        peakDataPercent,
        node.name + " Peak",
        color,
        shouldDisplayPeak
      ),
    });
  });
  return { hourSeriesMetaData, dataMax };
};

const deriveMaxDataValue = (data: AnyVal) => {
  let dataMax = 0;
  data.forEach((datum: AnyVal) => {
    if (datum[1] > dataMax) {
      dataMax = datum[1];
    }
  });
  return dataMax;
};

export const deriveHourChartDatesMetaData = (
  parsedData: AnyVal[],
  nodes: SpaceNodeByIdMap,
  dateRange: AnyVal,
  comparisonDateRanges: AnyVal[],
  chartMetaData: ChartSeriesMetaDataItem[],
  shouldDisplayAverage: boolean,
  shouldDisplayPeak: boolean
) => {
  const hourSeriesMetaData: ChartSeriesMetaDataItem[] = [];

  const datesToCompare: DateRangeListItem[] = [
    { ...dateRange, indexOfDateRange: 0 },
    ...comparisonDateRanges,
  ];
  let dataMax = 0;
  datesToCompare.forEach(({ startDate, indexOfDateRange, endDate }) => {
    const spaceData = parsedData[indexOfDateRange];
    const comparisonLabel = deriveComparisonDatesLabel(startDate, endDate);

    const oldChartItem = chartMetaData.find(
      (item) => item.name === comparisonLabel
    );
    const color = deriveColor(oldChartItem, hourSeriesMetaData);
    Object.keys(spaceData).forEach((id: AnyVal) => {
      const chartData = spaceData[id];
      const node = nodes[id];
      const newMax = deriveMaxDataValue(chartData.Peak);
      dataMax = newMax;
      hourSeriesMetaData.push({
        name: comparisonLabel,
        seriesName: comparisonLabel + " Average",
        color,
        id: node.id,
        type: ChartSeriesType.Average,
        series: deriveHourLineSeries(
          chartData.Avg,
          comparisonLabel + " Average",
          color,
          shouldDisplayAverage
        ),
        percentageSeries: deriveHourLineSeries(
          chartData.AvgPercent,
          comparisonLabel + " Average",
          color,
          shouldDisplayAverage
        ),
      });
      hourSeriesMetaData.push({
        name: comparisonLabel,
        seriesName: comparisonLabel + " Peak",
        color,
        id: node.id,
        type: ChartSeriesType.Peak,
        series: deriveHourScatterSeries(
          chartData.Peak,
          comparisonLabel + " Peak",
          color,
          shouldDisplayPeak
        ),
        percentageSeries: deriveHourScatterSeries(
          chartData.PeakPercent,
          comparisonLabel + " Peak",
          color,
          shouldDisplayPeak
        ),
      });
    });
  });
  return { hourSeriesMetaData, dataMax };
};

export const deriveHourChartOptions = (
  parsedData: AnyVal,
  isDateComparison: boolean,
  shouldDisplayAverage: boolean,
  shouldDisplayPeak: boolean,
  isYAxisZoomed: boolean,
  options: Highcharts.Options,
  isYAxisPercentage: boolean,
  nodes: SpaceNodeByIdMap,
  comparisonSpaceIds: string[],
  comparisonDateRanges: AnyVal[],
  chartMetaData: ChartSeriesMetaDataItem[],
  dateRange: AnyVal,
  capacity: number,
  showEarlyPreview: boolean
) => {
  const newIsYAxisPercentage = isDateComparison ? false : isYAxisPercentage;
  const { hourSeriesMetaData, dataMax } = isDateComparison
    ? deriveHourChartDatesMetaData(
        parsedData,
        nodes,
        dateRange,
        comparisonDateRanges,
        chartMetaData,
        shouldDisplayAverage,
        shouldDisplayPeak
      )
    : deriveHourChartSpacesMetaData(
        parsedData,
        nodes,
        comparisonSpaceIds,
        chartMetaData,
        shouldDisplayAverage,
        shouldDisplayPeak
      );
  const series = hourSeriesMetaData.map((item) =>
    newIsYAxisPercentage ? item.percentageSeries : item.series
  );
  const yAxisMax = deriveChartYAxisMax(
    newIsYAxisPercentage,
    isYAxisZoomed,
    capacity,
    dataMax
  );

  const showSecondYAxis = Boolean(comparisonSpaceIds.length === 1);
  const yAxisOptions = deriveDefaultYAxisOptions(
    yAxisMax,
    capacity,
    showSecondYAxis,
    isYAxisZoomed,
    newIsYAxisPercentage,
    showEarlyPreview
  );
  const deriveToolTip = () => {
    if (showEarlyPreview) {
      return deriveEarlyPreviewHourChartTooltip();
    }
    if (isDateComparison) {
      return deriveHourSeriesDatestooltip(capacity);
    }
    if (newIsYAxisPercentage) {
      return deriveHourChartSpacesPercentagetooltip();
    }
    const showPercent = Boolean(comparisonSpaceIds.length === 1);
    return deriveHourChartSpacestooltip(showPercent);
  };

  const toolTip = deriveToolTip();

  const newHourOptions = {
    plotOptions: {
      line: {
        opacity: showEarlyPreview ? 0.5 : 0.9,
        dashStyle: showEarlyPreview ? "Dash" : "Solid",
      },
    },
    credits: {
      enabled: false,
    },
    chart: {
      type: "mixed",
      height: 300,
    },
    title: { text: undefined }, // hides the title
    xAxis: {
      tickInterval: 1,
      labels: {
        formatter: function (this: AnyVal) {
          return formatToTwelveHour(this.value);
        },
      },
    },
    exporting: {
      enabled: false,
    },
    ...options,
    ...yAxisOptions,
    ...toolTip,
    series,
  };
  return { newHourOptions, hourSeriesMetaData, dataMax };
};
