export const formatPercentage = (value: number | string, total: number) => {
  if (total <= 0) return "0%";
  return `${Math.round((100 * Number(value)) / total)}%`;
};

export const oneMinuteMS = 1000 * 60;

export const formatToTwelveHour = (hour: number | string) => {
  hour = Number(hour);
  if (isNaN(hour)) return "N/A";
  if (hour === 0) return "12am";
  if (hour < 12) return `${hour}am`;
  if (hour === 12) return `${hour}pm`;
  return `${hour - 12}pm`;
};
